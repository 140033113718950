'use client'

import { ensureAuthenticated, getUsername } from '@/services/AuthService'
import { fetchWhoami } from '@/services/WhoamiService'
import { Entitlement } from '@/services/models'
import { Route, getRoutes } from '@/services/routes'
import { Backdrop } from '@mui/material'
import { CustomUserClaim } from '@okta/okta-auth-js'
import { redirect, usePathname } from 'next/navigation'
import React, { PropsWithChildren, useContext } from 'react'
import useSWR from 'swr'

export type AuthContextType = {
  entitlements: Entitlement[]
  username: CustomUserClaim | CustomUserClaim[]
}

const AuthContext = React.createContext<AuthContextType>({ entitlements: [], username: {} })
AuthContext.displayName = 'AuthContext'
export const useAuthContext = () => useContext(AuthContext)

const AuthProvider = ({ children }: PropsWithChildren) => {
  const path = usePathname()
  const { data, isLoading, error } = useSWR(
    'v1/whoami',
    async () => {
      const loggedInToken = await ensureAuthenticated()

      if (loggedInToken) {
        const username = await getUsername()
        const whoamiResponse = await fetchWhoami()
        return { username, ...whoamiResponse }
      }
      return undefined
    },
    { shouldRetryOnError: false },
  )
  if (isLoading) {
    return <Backdrop open={true}>Determining Credentials</Backdrop>
  }
  if (error) {
    return <Backdrop open={true}>Failed Determining Entitlements</Backdrop>
  }
  if (!data?.entitlements) {
    return <Backdrop open={true}>Redirecting to Login</Backdrop>
  }
  const requiredEntitlement = getRequiredEntitlementForPath(path || '')
  if (requiredEntitlement && !data.entitlements.includes(requiredEntitlement)) {
    redirect('/')
  }
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
}

function getRequiredEntitlementForPath(path: string): Entitlement | undefined {
  const pathRoute = getRoutes().find((r: Route) => {
    return new RegExp(r.path + '/?$').test(path)
  })
  return pathRoute?.entitlement
}

export default AuthProvider
