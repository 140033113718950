import { Entitlement } from '@/services/models'

export interface Route {
  label: string
  path: string
  entitlement?: Entitlement
  appBarLink: boolean
}

const routes: Route[] = [
  { label: 'Case', path: '/case', entitlement: 'CaseView', appBarLink: false },
  { label: 'Case Creation', path: '/case-creation', entitlement: 'CaseEdit', appBarLink: true },
  { label: 'Case Work Queue', path: '/case-work-queue', entitlement: 'CaseView', appBarLink: true },
  { label: 'Case Search', path: '/case-search', entitlement: 'CaseView', appBarLink: true },
  { label: 'Cross-references', path: '/cross-references', entitlement: 'XrefView', appBarLink: true },
  { label: 'Reporting', path: '#', appBarLink: true },
]

export function getRoutes() {
  return routes
}
