'use client'

import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'

import { useAuthContext } from '@/components/AuthProvider'
import LinkButton from '@/components/LinkButton'
import { getRoutes, Route } from '@/services/routes'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

function navLinkDataTestId(route: Route): string {
  return route.label.toLowerCase().replaceAll(' ', '-')
}

export default function ResponsiveAppBar({ toggleColorMode }: { toggleColorMode: () => void }) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const authContext = useAuthContext()
  const pagesWithDisabled = getRoutes()
    .filter((page) => page.appBarLink)
    .map((page) => ({
      ...page,
      disabled: page.entitlement ? !authContext.entitlements.find((value) => value === page.entitlement) : false,
      dataTestId: navLinkDataTestId(page),
    }))
  const theme = useTheme()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <AppBar position="static" component="div">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignContent="flex-start">
            <IconButton size="large" aria-label="menu" aria-haspopup="true" data-testid="menu-button" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pagesWithDisabled.map((page) => (
                <MenuItem key={page.label} onClick={handleCloseNavMenu} data-testid={page.dataTestId} disabled={page.disabled}>
                  <Button variant="text" href={page.path} className="noUnderline">
                    <Typography textAlign="center">{page.label}</Typography>
                  </Button>
                </MenuItem>
              ))}

              <IconButton onClick={toggleColorMode} color="inherit" aria-label="toggle theme">
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pagesWithDisabled.map((page) => (
              <LinkButton
                key={page.label}
                label={page.label}
                href={page.path}
                disabled={page.disabled}
                data-testid={page.dataTestId}
                variant="text"
              />
            ))}
          </Box>
          <IconButton sx={{ ml: 1, display: { xs: 'none', md: 'flex' } }} aria-label="toggle theme" onClick={toggleColorMode} color="inherit">
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
