'use client'
import getTheme from '@/app/theme'
import { PaletteMode } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import React, { useMemo } from 'react'
export default function ThemeRegistry(props: { children: React.ReactNode; mode: PaletteMode }) {
  const { children, mode } = props
  const theme = useMemo(() => {
    return getTheme(mode)
  }, [mode])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
