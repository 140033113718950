import { alpha, PaletteMode } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import React from 'react'

declare module '@mui/material/Button' {
  //creates a custom variant for "Button"
  interface ButtonPropsVariantOverrides {
    custom: true
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bolded: React.CSSProperties
    title: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bolded?: React.CSSProperties
    title?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bolded: true
    title: true
  }
}

const getTheme = (mode: PaletteMode) => {
  const paletteTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: '#4EC3E0',
      },
      secondary: {
        main: '#56585A',
      },
      darkBlue: {
        main: '#002E5D',
        light: alpha('#002E5D', 0.5),
        dark: alpha('#002E5D', 0.9),
        contrastText: '#55D3F2',
      },
      hotPink: {
        main: '#FF4FE2',
        light: '#FFBFEF',
        dark: '#b01784',
        contrastText: '#111',
      },
      error: {
        main: mode === 'light' ? '#D02536' : '#ff5566',
      },
      warning: {
        main: mode === 'light' ? '#AF6219' : '#FB8C25',
      },
      info: {
        main: '#0B78D0',
      },
      success: {
        main: '#4CAF50',
      },
    },
  })
  return createTheme(paletteTheme, {
    components: {
      MuiDatePicker: {
        defaultProps: {
          format: 'YYYY-MM-DD',
          slotProps: { textField: { size: 'small' } },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#fff',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'small',
          variant: 'contained',
        },
        variants: [
          {
            props: { variant: 'custom' },
            style: {
              border: '2px dashed', // this is an example of how to add a custom variant
            },
          },
        ],
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            color: '#fff',
          },
        },
      },
      MuiDataGrid: {
        defaultProps: {
          density: 'compact',
          autoHeight: true,
          disableVirtualization: true,
          slotProps: {
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          },
        },
        styleOverrides: {
          root: {
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: 'normal',
            },
            color: paletteTheme.palette.getContrastText(paletteTheme.palette.background.default),
          },
        },
      },
      MuiFilledInput: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
        },
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiListItem: {
        defaultProps: {
          dense: true,
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiLink: {
        defaultProps: {
          color: mode === 'light' ? paletteTheme.palette.darkBlue.main : paletteTheme.palette.primary.main,
        },
      },
      MuiFab: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
          InputLabelProps: { shrink: true },
          inputProps: { sx: { py: '5px' } },
        },
      },
      MuiToolbar: {
        defaultProps: {
          variant: 'dense',
        },
      },
    },
    typography: {
      fontSize: 18,
      h1: {
        fontSize: '2.25rem',
        color: mode === 'light' ? paletteTheme.palette.darkBlue.main : paletteTheme.palette.primary.main,
      },
      h2: {
        fontSize: '2rem',
        color: mode === 'light' ? paletteTheme.palette.darkBlue.main : paletteTheme.palette.primary.main,
      },
      h3: {
        fontSize: '1.75rem',
        color: mode === 'light' ? paletteTheme.palette.darkBlue.main : paletteTheme.palette.primary.main,
      },
      h4: {
        fontSize: '1.5rem',
        color: mode === 'light' ? paletteTheme.palette.darkBlue.main : paletteTheme.palette.primary.main,
      },
      h5: {
        fontSize: '1.25rem',
        color: mode === 'light' ? paletteTheme.palette.darkBlue.main : paletteTheme.palette.primary.main,
      },
      h6: {
        fontSize: '1rem',
        color: mode === 'light' ? paletteTheme.palette.darkBlue.main : paletteTheme.palette.primary.main,
        fontWeight: 'bold',
      },
      title: {
        fontSize: '2rem',
        color: mode === 'light' ? paletteTheme.palette.darkBlue.main : paletteTheme.palette.primary.main,
      },
      bolded: {
        fontWeight: 'bold',
      },
    },
  })
}

export default getTheme

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    darkBlue: PaletteColor
    hotPink?: PaletteColor
    red?: PaletteColor
    gold?: PaletteColor
    brown?: PaletteColor
    teal?: PaletteColor
    lightGreen?: PaletteColor
    darkGreen?: PaletteColor
    purple?: PaletteColor
    orange?: PaletteColor
  }

  interface PaletteOptions {
    darkBlue: PaletteColor
    hotPink?: PaletteColor
    red?: PaletteColor
    gold?: PaletteColor
    brown?: PaletteColor
    teal?: PaletteColor
    lightGreen?: PaletteColor
    darkGreen?: PaletteColor
    purple?: PaletteColor
    orange?: PaletteColor
  }
}
