'use client'
import ThemeRegistry from '@/app/theme-registry'
import AuthProvider from '@/components/AuthProvider'
import GlobalSnackbarProvider from '@/components/GlobalSnackbarProvider'
import Header from '@/components/Header'
import { Container, PaletteMode } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { SWRConfig } from 'swr'
import './globals.css'

export default function ClientLayout({ children }: { children: React.ReactNode }) {
  const [themeMode, setThemeMode] = useState<PaletteMode>('dark')
  useEffect(() => {
    const savedThemeMode = localStorage.getItem('themeMode')
    setThemeMode(savedThemeMode == 'dark' ? 'dark' : 'light')
  }, [setThemeMode])
  const toggleColorMode = React.useCallback(() => {
    setThemeMode((prevMode: PaletteMode) => {
      const newThemeMode = prevMode === 'light' ? 'dark' : 'light'
      localStorage.setItem('themeMode', newThemeMode)
      return newThemeMode
    })
  }, [setThemeMode])
  const year = new Date().getFullYear()
  return (
    <ThemeRegistry mode={themeMode}>
      <SWRConfig value={{ revalidateOnFocus: false, shouldRetryOnError: false }}>
        <AuthProvider>
          <GlobalSnackbarProvider>
            <Container maxWidth={false} id="root-container">
              <Header toggleColorMode={toggleColorMode} />
              <Container maxWidth={false} id="main-container">
                {children}
              </Container>
              <Box component="footer" color="primary.contrastText" bgcolor="primary.main">
                <Typography>&copy; {year} Bremer Financial Corporation.</Typography>
              </Box>
            </Container>
          </GlobalSnackbarProvider>
        </AuthProvider>
      </SWRConfig>
    </ThemeRegistry>
  )
}
