'use client'
import { OktaAuth } from '@okta/okta-auth-js'

/**
 * @throws {import('@okta/okta-auth-js').TokenManagerError} when parseFromUrl includes an error in redirect
 */
export const ensureAuthenticated = async (): Promise<string | undefined> => {
  const authClient = getClient()
  const tokens = await authClient.tokenManager.getTokens()

  if (authClient.token.isLoginRedirect()) {
    const { tokens } = await authClient.token.parseFromUrl()
    authClient.tokenManager.setTokens(tokens)
    const originalUri = authClient.getOriginalUri()
    if (originalUri && window.location.href != originalUri) {
      window.location.href = originalUri
    }
  } else if (!tokens?.idToken || authClient.tokenManager.hasExpired(tokens.idToken)) {
    authClient.tokenManager.clear()
    authClient.setOriginalUri(window.location.toString())
    await authClient.signInWithRedirect()
  }

  await authClient.start()
  return authClient.getIdToken()
}

export const getIdToken = () => {
  const authClient = getClient()
  return authClient.getIdToken()
}

export const getUsername = async () => {
  const authClient = getClient()
  return (await authClient.getUser()).uid
}

let _authClient: OktaAuth | undefined
const getClient = () => {
  return (
    _authClient ||
    new OktaAuth({
      clientId: process.env.NEXT_PUBLIC_OKTA_AUDIENCE,
      responseMode: 'fragment',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      pkce: true,
      redirectUri: window.location.origin,
      issuer: 'https://bremer-wf.okta.com/oauth2/ausdk8bu80YSG2pFQ4x7',
      tokenManager: {
        autoRenew: true,
      },
    })
  )
}
