import { getIdToken } from '@/services/AuthService'
import { FetcherError } from '@/services/CustomErrors'

export async function fetchWithAuth(url: string, options: RequestInit = {}): Promise<Response> {
  const idToken = getIdToken()
  if (idToken === undefined) {
    throw Error('not logged in')
  }
  const optionsWithAuth = {
    ...options,
    headers: {
      ...options.headers,
      authorization: `Bearer ${idToken}`,
    },
  }
  return await fetch(url, optionsWithAuth)
}

/**
 * @throws FetcherError with status from http response and message parsed from body if available
 */
export async function handleFetchErrors(response: Response) {
  if (response.status >= 300) {
    let message: string | undefined = undefined
    try {
      const responseJson = await response.json()
      if (typeof responseJson === 'object') {
        message = 'message' in responseJson ? (responseJson.message as string) : undefined
      }
    } catch (e) {
      /* ignore errors during error handling */
    }
    const err = new FetcherError(message)
    err.status = response.status
    throw err
  }
}
