import ResponsiveAppBar from '@/components/ResponsiveAppBar'
import { Box, Grid, Typography } from '@mui/material'
import Image from 'next/image'

function environmentMessage() {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT != 'PRODUCTION') {
    return (
      <Grid item>
        <Typography color="hotPink.dark" fontWeight="bold">
          **** THIS IS A NON-PRODUCTION ENVIRONMENT ****
        </Typography>
      </Grid>
    )
  }
  return <></>
}

export default function Header({ toggleColorMode }: { toggleColorMode: () => void }) {
  return (
    <header>
      <Grid container alignItems="center">
        <Grid item xs={3} md={2} sx={{ paddingX: 2 }}>
          <Image src="/bremer-logo.svg" alt="Bremer Bank" width="100" height="60" />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="title" aria-label="Case Management">
            Case Management
          </Typography>
        </Grid>
        {environmentMessage()}
      </Grid>
      <Box sx={{ pb: 0.5 }}>
        <ResponsiveAppBar toggleColorMode={toggleColorMode} />
      </Box>
    </header>
  )
}
