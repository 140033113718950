import { FetcherError } from '@/services/CustomErrors'
import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { AlertColor } from '@mui/material/Alert/Alert'
import crypto from 'crypto'
import React, { useCallback, useContext, useState } from 'react'

interface GlobalSnackbarItem {
  id?: string
  severity?: AlertColor
  title?: string
  message: string
  autoDismiss?: number //ms
}

interface KeyedGlobalSnackbarItem extends GlobalSnackbarItem {
  id: string
}

interface GlobalSnackbarContextType {
  pushAlert: (a: GlobalSnackbarItem) => string
  dismissAlert: (id: string) => void
  // following helper methods are for pushing auto dismissing messages
  // pushError also accepts unknown, so it can take any error that may have been thrown
  pushError: (message: string | unknown, timeoutMs?: number) => string
  pushWarning: (message: string, timeoutMs?: number) => string
  pushInfo: (message: string, timeoutMs?: number) => string
  pushSuccess: (message: string, timeoutMs?: number) => string
}

const GlobalSnackbarContext = React.createContext<GlobalSnackbarContextType>({
  pushAlert: () => '',
  dismissAlert: () => {},
  pushError: () => '',
  pushWarning: () => '',
  pushInfo: () => '',
  pushSuccess: () => '',
})
GlobalSnackbarContext.displayName = 'GlobalSnackbarContext'
export const useGlobalSnackbarContext = () => useContext(GlobalSnackbarContext)

const GlobalSnackbarProvider = ({ children }: { children: JSX.Element }) => {
  const [alerts, setAlerts] = useState<KeyedGlobalSnackbarItem[]>([])
  const dismissAlert = useCallback(
    (id: string) => {
      setAlerts((prevState) => {
        const idFound = prevState.findIndex((item) => item.id === id)
        return prevState.toSpliced(idFound, idFound === -1 ? 0 : 1)
      })
    },
    [setAlerts],
  )
  const pushAlert = useCallback(
    (a: GlobalSnackbarItem) => {
      const id = a.id ?? crypto.randomBytes(12).toString('hex')
      setAlerts((prevState) => {
        const alreadyExists = prevState.findIndex((item) => item.id === id) !== -1
        return alreadyExists ? prevState : prevState.concat({ ...a, id })
      })
      if (a.autoDismiss) {
        setTimeout(() => dismissAlert(id), a.autoDismiss)
      }
      return id
    },
    [dismissAlert, setAlerts],
  )
  const pushError = useCallback(
    (message: string | unknown, timeoutMs = 4000) => {
      if (typeof message === 'string') {
        return pushAlert({ severity: 'error', message: message, autoDismiss: timeoutMs })
      }

      let messageText = 'something went wrong'
      if (message instanceof FetcherError) {
        if ((message.status ?? 0) >= 400 && (message.status ?? 0) < 500) {
          messageText = message.message || 'form appears to be invalid'
        } else if ((message.status || 0) >= 500) {
          messageText = 'service failure. Please try again.'
        } else {
          messageText = 'something went wrong contacting server'
        }
      }
      return pushAlert({ severity: 'error', message: messageText, autoDismiss: timeoutMs })
    },
    [pushAlert],
  )
  const pushWarning = useCallback(
    (message: string, timeoutMs = 4000) => pushAlert({ severity: 'warning', message: message, autoDismiss: timeoutMs }),
    [pushAlert],
  )
  const pushInfo = useCallback(
    (message: string, timeoutMs = 4000) => pushAlert({ severity: 'info', message: message, autoDismiss: timeoutMs }),
    [pushAlert],
  )
  const pushSuccess = useCallback(
    (message: string, timeoutMs = 4000) => pushAlert({ severity: 'success', message: message, autoDismiss: timeoutMs }),
    [pushAlert],
  )

  return (
    <GlobalSnackbarContext.Provider value={{ pushAlert, dismissAlert, pushError, pushWarning, pushInfo, pushSuccess }}>
      <div>
        <Snackbar open={alerts.length > 0} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <div>
            {alerts.map((alert) => (
              <Alert key={alert.id} severity={alert.severity} onClose={() => dismissAlert(alert.id)} sx={{ mb: 1 }}>
                <AlertTitle sx={{ textTransform: 'capitalize' }}>{alert.title ? alert.title : alert.severity}</AlertTitle>
                {alert.message}
              </Alert>
            ))}
          </div>
        </Snackbar>
      </div>
      {children}
    </GlobalSnackbarContext.Provider>
  )
}
export default GlobalSnackbarProvider
