'use client'
import Button, { ButtonProps } from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import { usePathname } from 'next/navigation'

interface LinkAttributes extends ButtonProps {
  label: string
  href: string
  className?: string
}

function normalizeHref(path: string): string {
  let filtered = path
  if (path.charAt(0) !== '/') {
    filtered = `/${path}`
  }

  if (filtered.charAt(filtered.length - 1) !== '/') {
    filtered = `${filtered}/`
  }
  return filtered
}

export default function LinkButton(props: LinkAttributes) {
  const { href, label, className, ...buttonProps } = props
  const theme = useTheme()
  const pathname = usePathname()
  const currentClassName = normalizeHref(pathname) === normalizeHref(href) ? 'current-page' : ''
  const classNameExtended = className ? `${className} ${currentClassName}` : currentClassName
  return (
    <Button
      key={label}
      href={href}
      className={classNameExtended}
      sx={{
        padding: theme.spacing(1),
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      }}
      {...buttonProps}
    >
      {label}
    </Button>
  )
}
